<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">简历库</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" size="small" clearable placeholder="请输入姓名" />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:4rem">手机号:</span>
              <el-input v-model="retrievalData.mobile" type="text" size="small" clearable placeholder="请输入手机号" />
            </div>
            <div title="人才等级" class="searchboxItem ci-full">
              <span class="itemLabel">期望城市:</span>
              <el-cascader
                  :props="propsarea"
                  v-model="retrievalData.workAreaId"
                  :options="areaList"
                  size="small"
                  clearable
              ></el-cascader>
            </div>
            <div title="推荐系数" class="searchboxItem ci-full">
              <span class="itemLabel">薪资要求:</span>
              <el-input-number v-model="retrievalData.compensationMin" size="small" :min="1" :max="retrievalData.compensationMax||250" :controls="false" placeholder="请输入最小值" style="width: 42%;" clearable :precision="0"></el-input-number>
              <span style="min-width: 4%">-</span>
              <el-input-number v-model="retrievalData.compensationMax" size="small" :min="retrievalData.compensationMin" :max="250" :controls="false" placeholder="请输入最大值" style="width: 42%;" clearable :precision="0"></el-input-number>
              <span style="min-width: 12%;padding-right: 0">千元</span>
            </div>
            <div class="btnBox" style="margin-left:20px">
              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="个人简介"
                  align="left"
                  min-width="460"
              >
                <template slot-scope="{ row }">
                  <el-row>
                    <el-col :span="8">
                      <el-image
                          v-if="row.photoUrl"
                          style="width: 80px;height: 80px;background: #ddd"
                          fit="contain"
                          :src="row.photoUrl"
                          :preview-src-list="[row.photoUrl]">
                      </el-image>
                      <span v-else style="display:inline-block;width: 80px;height: 80px;background: #ddd;color: #999;text-align: center;line-height: 80px;">暂无头像</span>
                    </el-col>
                    <el-col :span="16">
                      <p>{{ row.username }}</p>
                      <p>
                        <span v-if="row.sex">{{ $setDictionary("SEX", row.sex) }}</span>
                        <span v-if="row.age&&row.age!='0岁'">&nbsp;|&nbsp;{{ row.age }}</span>
                        <span v-if="row.areaName">&nbsp;|&nbsp;现居{{ row.areaName }}</span>
                        <span v-if="row.jobSeekingState">&nbsp;|&nbsp;{{ $setDictionary("HR_JOB_SEEKING_STATE", row.jobSeekingState) }}</span>
                      </p>
                      <p v-if="row.expectation">
                        期望：
                        <span v-if="row.expectation.workAreaName">{{row.expectation.workAreaName}}</span>
                        <span v-if="row.expectation.positionName">&nbsp;|&nbsp;{{row.expectation.positionName}}</span>
                        <span v-if="row.expectation.compensation">&nbsp;|&nbsp;{{row.expectation.compensation}}</span>
                        <span v-if="row.expectation.arrivalTime">&nbsp;|&nbsp;{{$setDictionary("HR_ARRIVAL_TIME", row.expectation.arrivalTime)}}</span>
                      </p>
                      <p v-if="row.skilledList && row.skilledList.length> 0">
                        精通：
                        <span v-for="(item,index) in row.skilledList" :key="index">
                          {{ item }}
                          <template v-if="index != (row.skilledList.length - 1)">&nbsp;|&nbsp;</template>
                        </span>
                      </p>
                      <p>
                        <span v-if="row.talentLevel">{{ row.talentLevel }}星人才&nbsp;&nbsp;</span>
                        <span v-if="row.recommendLevel">{{ row.recommendLevel }}星推荐</span>
                      </p>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                  label="工作经历"
                  align="left"
                  prop="mobile"
                  min-width="360"
              >
                <template slot-scope="{ row }">
                  <p v-for="(item,index) in row.workExperiences" :key="index">
                    <span v-if="item.entryTime&&item.resignationTime">{{item.entryTime}}-{{item.resignationTime}}</span>
                    <span v-if="item.companyName">&nbsp;|&nbsp;{{item.companyName}}</span>
                    <span v-if="item.positionName">&nbsp;|&nbsp;{{item.positionName}}</span>
                  </p>
                  <p v-if="!row.workExperiences||row.workExperiences.length == 0">--</p>
                </template>
              </el-table-column>
              <el-table-column
                  label="教育经历"
                  align="left"
                  prop="mobile"
                  show-overflow-tooltip
                  min-width="360"
              >
                <template slot-scope="{ row }">
                  <p v-for="(item,index) in row.educationExperiences" :key="index">
                    <span v-if="item.education">{{ $setDictionary("HR_EDUCATION", item.education) }}</span>
                    <span v-if="item.manpowerRecruitment != ''">&nbsp;|&nbsp;{{item.manpowerRecruitment?'统招':'非统招'}}</span>
                    <br>
                    <span v-if="item.enrollmentYear&&item.leaveYear">{{item.enrollmentYear}}-{{item.leaveYear}}</span>
                    <span v-if="item.schoolName">&nbsp;|&nbsp;{{item.schoolName}}</span>
                    <span v-if="item.major">&nbsp;|&nbsp;{{item.major}}</span>
                  </p>
                  <p v-if="!row.educationExperiences||row.educationExperiences.length == 0">--</p>
                </template>
              </el-table-column>
              <el-table-column
                  label="沟通情况"
                  align="left"
                  prop="mobile"
                  show-overflow-tooltip
                  min-width="300"
              >
                <template slot-scope="{ row }">
                  <p v-for="(item,index) in row.contactLogs" :key="index">
                    <span v-if="item.createTime">{{item.createTime}}&nbsp;|&nbsp;</span>
                    <span v-if="item.remark">{{item.remark}}&nbsp;|&nbsp;</span>
                    <span v-if="item.contactResult">{{ $setDictionary("HR_CONTACT_RESULT", item.contactResult) }}</span>
                  </p>
                  <p v-if="!row.contactLogs||row.contactLogs.length == 0">--</p>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="scope">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="toDetail(scope.row)"
                  >查看详情</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="toCommunicationRecord(scope.row)"
                  >沟通记录</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="pushResume(scope.row)"
                  >推送简历</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
      <pushResume
          ref="pushResume"
          @eventBus="pushResumeMoreBack"
      ></pushResume>
    </div>

  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pushResume from "@/views/recruitModule/resumeLibrary/pushResume";
export default {
  name: "resumeList",
  components: {
    Empty,
    PageNum,
    pushResume,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {
      // 检索数据
      retrievalData: {
        userName: "", // 姓名
        mobile: "", // 手机号
        workAreaId: "", // 期望城市
        compensationMin: undefined,
        compensationMax: undefined
      },

      areaList: [], // 期望城市List
      // 所在城市 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      salaryRequireList: [], // 薪资要求List
      currentPage: ''
    };
  },
  computed: {},
  created() {
    if(this.$route.query.retrievalData&&this.$route.query.retrievalData.userName){
      this.retrievalData.userName = this.$route.query.retrievalData.userName
    }
    if(this.$route.query.retrievalData&&this.$route.query.retrievalData.mobile){
      this.retrievalData.mobile = this.$route.query.retrievalData.mobile
    }
    if(this.$route.query.retrievalData&&this.$route.query.retrievalData.workAreaId){
      this.retrievalData.workAreaId = this.$route.query.retrievalData.workAreaId
    }
    if(this.$route.query.retrievalData&&this.$route.query.retrievalData.compensationMin){
      this.retrievalData.compensationMin = this.$route.query.retrievalData.compensationMin
    }
    if(this.$route.query.retrievalData&&this.$route.query.retrievalData.compensationMax){
      this.retrievalData.compensationMax = this.$route.query.retrievalData.compensationMax
    }
    console.log(this.$route.query.currentPage)
    if(this.$route.query.currentPage&&this.$route.query.currentPage > 1){
      this.getData(this.$route.query.currentPage)
    }else if(this.$route.query.currentPage == '1'){
      this.getData()
    }else{
      this.getData()
    }


    // this.getDropDownData()
    this.getcity()
  },
  methods: {
    // 获取 all- 下拉数据
    // getDropDownData() {
    //   //薪资要求list
    //   const salaryRequireList = this.$setDictionary(
    //       "MINI_JOB_SERVICE_SALARY",
    //       "list"
    //   );
    //   for (const key in salaryRequireList) {
    //     this.salaryRequireList.push({
    //       value: key,
    //       label: salaryRequireList[key],
    //     });
    //   }
    // },
    // 行政区划
    getcity() {
      this.$post("/sys/area/tree")
          .then((res) => {
            this.areaList = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      this.currentPage = pageNum
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.workAreaId) {
        params.workAreaId = this.retrievalData.workAreaId;
      }
      if (this.retrievalData.compensationMin&&this.retrievalData.compensationMax) {
        params.compensationMin = this.retrievalData.compensationMin;
        params.compensationMax = this.retrievalData.compensationMax;
      }else if((!this.retrievalData.compensationMin&&this.retrievalData.compensationMax)||(this.retrievalData.compensationMin&&!this.retrievalData.compensationMax)){
        this.$message.error("请补全薪资要求再进行查询");
        return false;
      }
      this.doFetch({
        url: "/hr/talent/resume/page",
        params,
        pageNum
      });
    },
    // 查看详情
    toDetail(row) {
      this.$router.push({
        path: "/hr/recruitModule/resumeLibrary/resumeDetail",
        query: {
          talentId: row.talentId || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 备注
    toCommunicationRecord(row){
      this.$router.push({
        path: "/hr/recruitModule/resumeLibrary/communicationRecord",
        query: {
          talentId: row.talentId || '',
          userName: row.username || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 推送简历
    pushResume(row){
      this.$refs.pushResume.showPopUp(row.talentId);
    },
    // 批量选课件
    pushResumeMoreBack(ret, talentId) {
      let recruitmentList = [];
      // this.baseform.recommendName = ret.courseName
      ret.map((el) => {
        recruitmentList.push(el.recruitmentId);
      });
      this.$post("/hr/talent/resume/job/push", {
        talentId,
        recruitmentList,
      }).then((res) => {
        if (res.status == "0") {
          this.$message({
            message: "推送成功",
            type: "success",
          });
        }
      });
    },
  },
  watch: {

  }
};
</script>
<style lang="less">
.el-rate__icon {
  margin-right: 0;
}
</style>
<style>
 .el-tooltip__popper{
   max-width: 30%;
 }
</style>
