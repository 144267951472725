<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="90%"
    title="推送简历"
    :center="true"
    :before-close="doClose"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
          <div title="岗位名称" class="searchboxItem">
            <span class="itemLabel">岗位名称:</span>
            <el-input
              clearable
              v-model="positionName"
              type="text"
              size="small"
              placeholder="请输入岗位名称"
            />
          </div>
          <span title="企业名称" class="searchboxItem ci-full flexcc">
            <span class="itemLabel">企业名称:</span>
            <el-select
                clearable
                v-model="compId"
                filterable
                remote
                size="small"
                :remote-method="searchCopmp"
                placeholder="请至少输入两个字搜索"
            >
              <el-option
                  size="small"
                  v-for="item in compList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
              ></el-option>
            </el-select>
          </span>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="recruitmentId"
            style="width: 100%"
            @selection-change="change"
            stripe
            border
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="企业名称"
              align="center"
              show-overflow-tooltip
              prop="compName"
            />
            <el-table-column
              label="岗位名称"
              align="center"
              show-overflow-tooltip
              prop="postName"
            />
            <el-table-column
                label="创建日期"
                align="center"
                show-overflow-tooltip
                prop="createTime"
            />
            <el-table-column
                label="有效期"
                align="center"
                show-overflow-tooltip
                prop="jobValidityPeriod"
            >
              <template slot-scope="scope">
                {{
                  $setDictionary("JOB_VALIDITY_PERIOD", scope.row.jobValidityPeriod)
                }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>选择企业</p>
            <p>（{{ resumeArr.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div
                v-for="(item, index) in resumeArr"
                :key="index"
                class="BatchforFlex"
              >
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img
                    :src="require('@/assets/close_.png')"
                    alt=""
                    @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)"
                  />
                </div>
                <span style="padding-left:5px">{{
                  item.compName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        class="dialog-footer"
        style="margin-top:10px;display:flex;justify-content: center;"
      >
        <div>
          <el-button @click="dialogClose">取 消</el-button>
          <el-button class="bgc-bv" @click="doOk">推 送</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import treePopupQuestion from "@/components/treePopupQuestion";
// import { mapGetters } from "vuex";
export default {
  name: "pushResumeTable",
  components: {
    Empty,
    PageNum,
    // treePopupQuestion,
  },
  mixins: [List],
  data() {
    return {
      positionName: "",
      traintype: "",
      multipleTable: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      dialogVisible: false,
      
      start: false,
      resumeArr: [],
      resumeArrall: [],
      compId: "",
      compList: [], // 单位
      propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  computed: {
    // ...mapGetters({
    //   traintypeList: "getTraintype",
    // }),
  },
  created() {
    // this.getTree();
  },
  updated() {
    setTimeout(() => {
      this.tableData.forEach((item, index) => {
        this.$refs.multipleTable.toggleRowSelection(
          item,
          this.resumeArr.some((one) => item.recruitmentId == one.recruitmentId)
        );
      });
    }, 200);
  },
  methods: {
    /**
     * 单位
     */
    searchCopmp(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/likeCompName", { compName: query }).then(
            (res) => {
              this.compList = res.data;
            }
        );
      } else {
        this.compList = [];
      }
    },    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    tinit() {
      this.init();
    },
    change(rowArr) {
      console.log(rowArr);
      this.resumeArr = rowArr;
      this.multipleTable = rowArr;
    },
    showPopUp(talentId) {
      this.dialogVisible = true;
      this.talentId = talentId;
      this.start = true;
      if (this.start) {
        this.getData();
        // this.getconfigquestionbank();
      }
    },
    /* 题库类别 */
    // getTree() {
    //   this.$post(
    //     "/miniapp/category/enable/tree",
    //     { disabledLevels: "1,2,3" },
    //     3000,
    //     true,
    //     2
    //   ).then((ret) => {
    //     this.TreeList = ret.data;
    //   });
    // },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.talentId || "",
      };
      if (this.positionName) {
        params.positionName = this.positionName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.start) {
        this.doFetch({
          url: "/hr/talent/resume/job/page",
          params,
          pageNum,
        });
      }
      setTimeout(() => {
        this.tableData.forEach((item, index) => {
          this.resumeArr.forEach((items, index) => {
            this.tableData.forEach((item, index) => {
              if (items.recruitmentId == item.recruitmentId) {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              }
            });
          });
        });
      }, 200);
    },
    // getconfigquestionbank() {
    //   this.$post("/biz/project/queryConfigQuestionBank", {
    //     talentId: this.talentId,
    //   })
    //     .then((ret) => {
    //       console.log(ret);
    //       this.resumeArr = ret.data;
    //     })
    //     .catch((err) => {
    //       return;
    //     });
    // },
    deleteImg(itemobj) {
      var newArr = this.resumeArr.filter(
        (item) => item.recruitmentId != itemobj.recruitmentId
      );
      this.resumeArr = newArr;
      this.multipleTable.find((el) => {
        if (el.recruitmentId == itemobj.recruitmentId) {
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { resumeArr } = this;
      if (resumeArr.length) {
        this.$emit("eventBus", resumeArr, this.talentId);
        this.dialogVisible = false;
        this.resumeArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要推送的企业",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.resumeArr = [];
      this.positionName = ''
      this.compId = ''
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    doClose() {
      this.dialogVisible = false;
      this.resumeArr = [];
      this.$refs.multipleTable.clearSelection();
      this.positionName = ''
      this.compId = ''
      //   this.$emit("eventBus");
      // this.params = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
  .Batch {
    padding: 1rem 1rem;
    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .BatchBoxSecond {
        width: 100%;
        .BatchforBox {
          display: flex;
          flex-wrap: wrap;
          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;
            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
